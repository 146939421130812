'use strict';

var QQForm = React.createClass({
	displayName: 'QQForm',

	getInitialState: function getInitialState() {
		return {
			value: 'atLeast'
		};
	},
	update: function update(e) {
		e.preventDefault();
		var theVal = {
			selector: React.findDOMNode(this.refs.selectorName).value,
			type: this.state.value,
			amount: {
				one: React.findDOMNode(this.refs.amountOne).value,
				two: React.findDOMNode(this.refs.amountTwo).value
			}
		};
		if (!theVal.selector || !theVal.amount.one) {
			return;
		}
		this.props.onUpdate(theVal);
	},
	displayAmount: function displayAmount(e) {
		this.setState({ value: e.target.value });
	},
	render: function render() {
		var isBetween = undefined;
		if (this.state.value == 'between') {
			isBetween = true;
		} else {
			isBetween = false;
		}
		return React.createElement(
			'section',
			{ className: 'formControl' },
			React.createElement(
				'h2',
				null,
				'Build a query'
			),
			React.createElement(
				'form',
				{ onSubmit: this.update },
				React.createElement(
					'div',
					{ className: 'inputRow' },
					React.createElement(
						'label',
						{ htmlFor: 'element', className: 'inputLabel' },
						'Which element will be counted ',
						React.createElement(
							'span',
							{ className: 'hint hint--bottom', 'data-hint': 'Provide the elements to be selected in the query' },
							'?'
						)
					),
					React.createElement('input', { type: 'text', id: 'element', autoFocus: true, ref: 'selectorName', placeholder: 'ex. ul li', required: true })
				),
				React.createElement(
					'div',
					{ className: 'inputRow' },
					React.createElement(
						'label',
						{ htmlFor: 'type' },
						'Type of Query ',
						React.createElement(
							'span',
							{ className: 'hint hint--bottom hint--bounce', 'data-hint': 'What kind of query will be used to count the elements' },
							'?'
						)
					),
					React.createElement(
						'select',
						{ id: 'type', ref: 'type', onChange: this.displayAmount, value: this.state.value },
						React.createElement(
							'option',
							{ value: 'atLeast' },
							'At-Least'
						),
						React.createElement(
							'option',
							{ value: 'atMost' },
							'At-Most'
						),
						React.createElement(
							'option',
							{ value: 'between' },
							'At-Least / At-Most'
						)
					)
				),
				React.createElement(
					'div',
					{ className: 'inputRow' },
					React.createElement(
						'label',
						{ 'for': 'amount', className: 'inputLabel' },
						'Amount of Items ',
						React.createElement(
							'span',
							{ className: 'hint hint--bottom hint--bounce', 'data-hint': 'Items to count' },
							'?'
						)
					),
					React.createElement('input', { type: 'number', ref: 'amountOne', required: true, placeholder: isBetween ? 'At least # of items to count' : '# of items to count' }),
					React.createElement('input', { type: isBetween ? 'number' : 'hidden', ref: 'amountTwo', required: true, placeholder: 'At most # of items to count' })
				),
				React.createElement('input', { type: 'submit', className: 'submit', value: 'Create Query' })
			)
		);
	}
});

var QQExample = React.createClass({
	displayName: 'QQExample',

	getInitialState: function getInitialState() {
		return {
			items: ['i', 'i', 'i', 'i', 'i']
		};
	},
	addItem: function addItem() {
		var newArray = this.state.items;
		newArray.push("i");
		this.setState({ arr: newArray });
		console.log(this.state.items);
	},
	removeItem: function removeItem() {
		if (this.state.items.length > 1) {
			var newArray = this.state.items;
			newArray.pop();
			this.setState({ arr: newArray });
		} else {
			return;
		}
	},
	render: function render() {
		var itemObjects = this.state.items.map(function (item) {
			return React.createElement('li', { className: 'item' });
		});
		return React.createElement(
			'div',
			null,
			React.createElement(
				'style',
				null,
				this.props.styles
			),
			React.createElement(
				'h2',
				null,
				'Try it out'
			),
			React.createElement(
				'p',
				null,
				'Your quantity query will be reflected on the items below by a change in colour. Add and remove items to see the styling be applied when the query matches.'
			),
			React.createElement(
				'header',
				{ className: 'controls' },
				React.createElement(
					'div',
					{ onClick: this.addItem, className: 'itemClick' },
					React.createElement('i', { className: 'fa fa-plus-circle ' }),
					' Add Item'
				),
				React.createElement(
					'div',
					{ onClick: this.removeItem, className: 'itemClick' },
					React.createElement('i', { className: 'fa fa-minus-circle' }),
					' Remove Item'
				)
			),
			React.createElement(
				'section',
				{ className: 'itemList' },
				React.createElement(
					'ul',
					null,
					itemObjects
				)
			)
		);
	}
});

var QQDisplay = React.createClass({
	displayName: 'QQDisplay',

	render: function render() {
		var type = this.props.data.type,
		    amountOne = this.props.data.amount.one,
		    amountTwo = this.props.data.amount.two,
		    selector = this.props.data.selector,
		    selectTail = selector.split(" ").splice(-1)[0];
		var pseudo,
		    equation = "// Build a query on the left",
		    styles;
		if (type === 'atLeast') {
			equation = selector + ':nth-last-child(n+' + amountOne + '), ' + selector + ':nth-last-child(n+' + amountOne + ') ~ ' + selectTail + ' { }';
			styles = 'section.itemList ul>li:nth-last-child(n+' + amountOne + '), section.itemList ul>li:nth-last-child(n+' + amountOne + ') ~ li { background: #D81B60 !important; }';
		} else if (type === 'atMost') {
			equation = selector + ':nth-last-child(-n+' + amountOne + '):first-child, ' + selector + ':nth-last-child(-n+' + amountOne + '):first-child ~ ' + selectTail + ' { }';
			styles = 'section.itemList ul>li:nth-last-child(-n+' + amountOne + '):first-child, section.itemList ul>li:nth-last-child(-n+' + amountOne + '):first-child ~ li { background: #D81B60 !important;  }';
		} else if (type === 'between') {
			equation = selector + ':nth-last-child(n+' + amountOne + '):nth-last-child(-n+' + amountTwo + '):first-child, ' + selector + ':nth-last-child(n+' + amountOne + '):nth-last-child(-n+' + amountTwo + '):first-child ~ ' + selectTail + ' { }';
			styles = 'section.itemList ul li:nth-last-child(n+' + amountOne + '):nth-last-child(-n+' + amountTwo + '):first-child, section.itemList ul li:nth-last-child(n+' + amountOne + '):nth-last-child(-n+' + amountTwo + '):first-child ~ li { background: #D81B60 !important; }';
		}
		return React.createElement(
			'div',
			{ className: 'displayBody' },
			React.createElement(
				'section',
				{ className: 'equationDisplay' },
				React.createElement(
					'h2',
					null,
					'Your Code'
				),
				React.createElement(
					'p',
					null,
					'Copy and paste the code below into your styles'
				),
				React.createElement(
					'code',
					null,
					equation
				)
			),
			React.createElement(QQExample, { styles: styles })
		);
	}
});

var QQApp = React.createClass({
	displayName: 'QQApp',

	getInitialState: function getInitialState() {
		return {
			data: {
				selector: '',
				amount: {
					one: '',
					two: ''
				},
				type: ''
			}
		};
	},
	onUpdate: function onUpdate(val) {
		// console.log(val)
		this.setState({
			data: {
				selector: val.selector,
				amount: {
					one: val.amount.one,
					two: val.amount.two
				},
				type: val.type
			}
		});
	},
	render: function render() {
		return React.createElement(
			'section',
			{ className: 'appBody' },
			React.createElement(QQForm, { onUpdate: this.onUpdate, data: this.state.data }),
			React.createElement(QQDisplay, { data: this.state.data })
		);
	}
});

$(function () {

	console.log('What\'s it built with? React, Babel and PostCSS! Help me improve or fix any issues! https://github.com/drewminns/qqui');
	console.log('Follow me on Twitter: @drewisthe');
	console.log('Find more crazy cool things at drewminns.com');
	React.render(React.createElement(QQApp, null), document.getElementById('content'));
	$('p.explain').on('click', function () {
		$('#lightbox').addClass('show');
	});
	$('.closeButton').on('click', function () {
		$('#lightbox').removeClass('show');
	});
	$(document).keyup(function (e) {
		if (e.keyCode == 27) {
			// escape key maps to keycode `27`
			$('#lightbox').removeClass('show');
		}
	});
});